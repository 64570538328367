import { Fragment, ReactElement } from 'react'
import { Box, Flex, Link, Text } from '@chakra-ui/react'

import { termsOfUseRoute, privacyPolicyRoute } from '@/routes'

import { Section, Container } from './styles'

export const Footer = (): ReactElement => {
  return (
    <Flex sx={{ flexDir: ['column', 'row'] }}>
      <Section
        sx={{
          pt: ['24px'],
          pb: ['16px'],
          display: ['box', 'none'],
        }}
      >
        <Container>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <LinkComponent />
          </Flex>
        </Container>
      </Section>

      <Section
        sx={{
          textAlign: ['center', 'start'],
          borderTop: ['12px solid', 'none'],
          borderColor: 'gray.320',
          pt: ['13px', '13px', '13px', '13px'],
          pb: ['19px', '13px', '13px', '13px'],
        }}
      >
        <Container
          sx={{
            d: ['box', 'flex'],
            alignItems: ['center'],
          }}
        >
          <Text
            sx={{
              mb: ['16px', 0],
              br: {
                d: ['none', 'block', 'none'],
              },
            }}
          >
            &copy;{new Date().getFullYear()} <strong>Data Wow Co., Ltd.</strong>
            <br />
            All Rights Reserved.
          </Text>

          <Box
            sx={{
              d: ['none', 'grid'],
              gridTemplateColumns: ['repeat(2, max-content)'],
              gap: ['24px'],
              ml: ['0', 'auto'],
            }}
          >
            <LinkComponent />
          </Box>

          <Text sx={{ ml: ['0', '24px'] }}>Made with ♥ in Bangkok</Text>
        </Container>
      </Section>
    </Flex>
  )
}

const LinkComponent = (): ReactElement => {
  return (
    <Fragment>
      <Link isExternal href={termsOfUseRoute.pathname}>
        Terms of Use
      </Link>
      <Link isExternal href={privacyPolicyRoute.pathname}>
        Privacy Policy
      </Link>
    </Fragment>
  )
}
