import {
  KornkanokP,
  PDPAForDPOTeacher,
  PDPAAwarenessPart2Teacher,
} from './teacherProfiles'
import { Course, Tab } from '../types'

export const TABS: Tab[] = [
  {
    label: 'รายละเอียดคอร์ส',
    key: 'description',
    hash: 'course-description',
    type: 'paragraph',
  },
  {
    label: 'ประวัติผู้สอน',
    key: 'teacherProfile',
    hash: 'teacher-profile',
    type: 'paragraph',
  },
  {
    label: 'คอร์สนี้เหมาะกับใคร',
    key: 'courseTarget',
    hash: 'course-target',
    type: 'unordered-list',
  },
  {
    label: 'สิ่งที่ผู้เรียนจะได้รับ',
    key: 'whatYouWillGet',
    hash: 'course-what-you-will-get',
    type: 'unordered-list',
  },
  {
    label: 'เนื้อหาของคอร์สนี้',
    key: 'sylabus',
    hash: 'course-sylabus',
    type: 'unordered-list',
  },
]

export const COURSES: Course[] = [
  {
    title: 'PDPA Awareness Training Part 1',
    metaDescription:
      'หลักสูตรเตรียมความพร้อมพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    subtitle:
      'หลักสูตรนี้จัดทำขึ้นมาเพื่อให้ความรู้ ความเข้าใจ และแนวปฏิบัติแก่พนักงานและบุคคลทั่วไปที่สนใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    listItemSubittle:
      'หลักสูตรเตรียมความพร้อมพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA) สำหรับพนักงานและบุคคลทั่วไป',
    slug: 'pdpa-awareness-training',
    price: '600 ฿',
    coursePageLink:
      'https://study.learnpdpa.com/courses/pdpa-awareness-training',
    takeCourseLink:
      'https://study.learnpdpa.com/courses/take/hr-pdpa-awareness-training/lessons/26373848-1',
    previewLink: 'https://study.learnpdpa.com/enroll/1160795?et=free_trial',
    image: '/images/Courses/Thumbnail-PDPAcourse-01.jpg',
    video: 'EFQ5AQyA_gc',
    bundlePromoImage: '/images/BundlePromo/Thumbnail-Promo-PDPAIntensive.jpg',
    bundlePromoLink:
      'https://study.learnpdpa.com/bundles/pdpa-intensive-bundle',
    description:
      'หลักสูตรนี้จัดทำขึ้นมาเพื่อให้ความรู้ ความเข้าใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) เพื่อสร้างความตระหนักรู้ในผลกระทบของการบังคับใช้กฎหมาย และให้สามารถเตรียมพร้อมการดำเนินการให้สอดคล้องกับกฎหมายได้ เพื่อลดความเสี่ยงที่จะนำไปสู่การรับผิดทางแพ่งและอาญา โดยหลักสูตรนี้นอกจากผู้เรียนจะได้เรียนรู้เกี่ยวกับความหมายและหลักการต่าง ๆ ของ PDPA และผลกระทบของกฎหมายแล้ว จะยังได้แนวปฏิบัติเพื่อนำไปประยุกต์ใช้ในองค์กรให้สอดคล้องตาม PDPA อีกด้วย',
    courseTarget: [
      'เจ้าของธุรกิจหรือผู้บริหารองค์กร',
      'หัวหน้างาน',
      'นักทรัพยากรบุคคล (HR)',
      'นักกฎหมายหรือนักกลยุทธ์องค์กร',
      'พนักงานทั่วไป',
      'บุคคลทั่วไปที่สนใจกฎหมายคุ้มครอง ข้อมูลส่วนบุคคล',
    ],
    whatYouWillGet: [
      'ความรู้ทั่วไปเกี่ยวกับ PDPA',
      'เพิ่มความตระหนักเกี่ยวกับผลกระทบของ PDPA',
      'แนวปฏิบัติให้สอดคล้องกับ PDPA',
    ],
    sylabus: [
      'แนะนำคอร์ส PDPA Awareness Training',
      'หัวข้อบทเรียน หลักสูตรกฎหมายคุ้มครองข้อมูลส่วนบุคคล',
      'ดาวน์โหลดสไลด์ประกอบการสอน',
      '7 หลักการสำคัญของกฎหมาย',
      'ตัวอย่างกรณีศึกษา และ Timeline การบังคับใช้ของ PDPA',
      '7 สาระสำคัญของ PDPA',
      'คำนิยาม และความสัมพันธ์เจ้าของข้อมูลส่วนบุคคล ผู้ควบคุมข้อมูลส่วนบุคคล และผู้ประมวลผลข้อมูลส่วนบุคคล',
      'ข้อมูลส่วนบุคคลคืออะไร และ การประมวลผลข้อมูลส่วนบุคคล',
      'ฐานทางกฎหมายในการประมวลผลข้อมูล',
      'ฐานความยินยอม, สิทธิของเจ้าของข้อมูลส่วนบุคคล, หน้าที่ของผู้ควบคุมข้อมูลส่วนบุคคลและผู้ประมวลผลข้อมูลส่วนบุคคล และบันทึกรายการการประมวลผลข้อมูลส่วนบุคคล',
      'ROP บันทึกรายการการประมวลผลข้อมูลส่วนบุคคล • โทษของการฝ่าฝืน PDPA • PDPA Compliance checklist • การดำเนินไปตามกฎหมาย PDPA • แหล่งข้อมูล PDPA',
      'ถาม-ตอบ เกี่ยวกับกฎหมาย PDPA',
    ],
    checklist: [
      'คอร์สความยาวประมาณ 1 ชั่วโมง',
      'เข้าเรียนได้ไม่จำกัดครั้งภายใน 1 ปี',
      'มีแบบทดสอบหลังเรียน',
      'มี certificate',
      'ออกแบบเนื้อหาโดยทีมผู้เชี่ยวชาญด้านกฎหมาย',
      'สอนโดยคุณกรกนก พรายแก้ว วิทยากรและที่ปรึกษาทางกฎหมาย ผู้เชี่ยวชาญด้านกฎหมายการค้าและพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    ],
    teacherProfile: <KornkanokP />,
  },
  {
    title: 'PDPA Awareness Training Part 2',
    metaDescription:
      'หลักสูตรเตรียมความพร้อมพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    subtitle:
      'หลักสูตรนี้จัดทำขึ้นมาเพื่อให้ความรู้ ความเข้าใจ และแนวปฏิบัติแก่พนักงานและบุคคลทั่วไปที่สนใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) โดยจะเน้นไปยังประกาศย่อย 5 ฉบับ ในปี 2565-2566',
    listItemSubittle:
      'หลักสูตรอัปเดตประกาศย่อย 5 ฉบับ ในปี 2565-2566 เพื่อให้ความรู้ ความเข้าใจ และแนวปฏิบัติแก่พนักงานและบุคคลทั่วไปที่สนใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    slug: 'pdpa-awareness-training-part-2',
    price: '600 ฿',
    coursePageLink:
      'https://study.learnpdpa.com/courses/pdpa-awareness-training-part-2',
    takeCourseLink:
      'https://study.learnpdpa.com/courses/take/PDPA-Awareness-Training-Part-2/lessons/56938960-pdpa-awareness-training-part-2',
    previewLink: 'https://study.learnpdpa.com/enroll/3058225?et=free_trial',
    image: '/images/Courses/Thumbnail-PDPAcourse-02.jpg',
    video: 'y1CCzrMUBQA',
    bundlePromoImage: '/images/BundlePromo/Thumbnail-Promo-PDPAPart2And3.jpg',
    bundlePromoLink:
      'https://study.learnpdpa.com/bundles/pdpa-awareness-training-part-2-and-3',
    description:
      'หลักสูตรนี้จัดทำขึ้นเพื่อสร้างความตระหนักรู้และเพิ่มพูนความเข้าใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) ให้มากยิ่งขึ้น โดยเจาะลึกเนื้อหาเกี่ยวกับประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล 5 ฉบับ ซึ่งบังคับใช้เพิ่มเติมในปี 2565-2566 เพื่อให้องค์กรสามารถนำแนวปฏิบัติ มาตรฐานและวิธีการต่างๆ ไปประยุกต์ใช้กับแผนการดำเนินงานให้มีความสอดคล้องกับกฎหมาย PDPA ในปัจจุบันได้อย่างมีประสิทธิภาพ อีกทั้งเพื่อลดความเสี่ยงที่อาจนำไปสู่การรับผิดทางแพ่ง ทางอาญา หรือทางปกครองได้',
    courseTarget: [
      'เจ้าของธุรกิจหรือผู้บริหารองค์กร',
      'หัวหน้างาน',
      'นักทรัพยากรบุคคล (HR)',
      'นักกฎหมายหรือนักกลยุทธ์องค์กร',
      'พนักงานทั่วไป',
      'บุคคลทั่วไปที่สนใจกฎหมายคุ้มครอง ข้อมูลส่วนบุคคล',
    ],
    whatYouWillGet: [
      'ความรู้เกี่ยวกับประกาศย่อย 5 ฉบับที่จำเป็น ในปี 2565-2566 เพื่อให้ผู้เรียนสามารถนำแนวปฏิบัติ มาตรฐานและวิธีการต่างๆ ไปประยุกต์ใช้กับแผนการดำเนินงานให้มีความสอดคล้องกับกฎหมาย PDPA ในปัจจุบันได้อย่างมีประสิทธิภาพ อีกทั้งเพื่อลดความเสี่ยงที่อาจนำไปสู่การรับผิดทางแพ่ง ทางอาญา หรือทางปกครองได้',
    ],
    sylabus: [
      'Timeline การบังคับใช้ PDPA และประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง มาตรการรักษาความมั่นคงปลอดภัยของผู้ควบคุมข้อมูลส่วนบุคคล พ.ศ. 2565',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง การยกเว้นการบันทึกรายการของผู้ควบคุมข้อมูลส่วนบุคคลซึ่งเป็นกิจการขนาดเล็ก พ.ศ. 2565',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง หลักเกณฑ์และวิธีการในการแจ้งเหตุการละเมิดข้อมูลส่วนบุคคล พ.ศ. 2565',
      'ตัวอย่างกรณีศึกษา และ Timeline การบังคับใช้ของ PDPA',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง หลักเกณฑ์และวิธีการในการจัดทำและเก็บรักษาบันทึกรายการของกิจกรรมการประมวลผลข้อมูลส่วนบุคคลสำหรับผู้ประมวลผลข้อมูลส่วนบุคคล พ.ศ. 2565',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง การจัดให้มีเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลตามมาตรา 41 (2) แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 พ.ศ. 2566',
      'แบบทดสอบหลังเรียน',
    ],
    checklist: [
      'คอร์สความยาวประมาณ 1 ชั่วโมง',
      'เข้าเรียนได้ไม่จำกัดครั้งภายใน 1 ปี',
      'มีแบบทดสอบหลังเรียน',
      'มี certificate',
      'ออกแบบเนื้อหาโดยทีมผู้เชี่ยวชาญด้านกฎหมาย',
      'สอนโดยคุณชยานันต์ ไชยคำภา วิทยากรและที่ปรึกษาทางกฎหมาย ผู้เชี่ยวชาญด้านพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    ],
    teacherProfile: <PDPAAwarenessPart2Teacher />,
  },
  {
    title: 'PDPA Awareness Training Part 3',
    metaDescription:
      'หลักสูตรเตรียมความพร้อมพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    subtitle:
      'หลักสูตรนี้จัดทำขึ้นมาเพื่อให้ความรู้ ความเข้าใจ และแนวปฏิบัติแก่พนักงานและบุคคลทั่วไปที่สนใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) โดยจะเน้นไปยังประกาศย่อย 5 ฉบับ ในปี 2567',
    listItemSubittle:
      'หลักสูตรอัปเดตประกาศย่อย 5 ฉบับ ในปี 2567 เพื่อให้ความรู้ ความเข้าใจ และแนวปฏิบัติแก่พนักงานและบุคคลทั่วไปที่สนใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    slug: 'pdpa-awareness-training-part-3',
    price: '600 ฿',
    coursePageLink:
      'https://study.learnpdpa.com/courses/pdpa-awareness-training-part-3',
    takeCourseLink:
      'https://study.learnpdpa.com/courses/take/PDPA-Awareness-Training-Part-3/lessons/56981633-pdpa-awareness-training-part-3',
    previewLink: 'https://study.learnpdpa.com/enroll/3058281?et=free_trial',
    image: '/images/Courses/Thumbnail-PDPAcourse-03.jpg',
    video: 'EhfEVFPWlKE',
    bundlePromoImage: '/images/BundlePromo/Thumbnail-Promo-PDPAPart2And3.jpg',
    bundlePromoLink:
      'https://study.learnpdpa.com/bundles/pdpa-awareness-training-part-2-and-3',
    description:
      'หลักสูตรนี้จัดทำขึ้นเพื่อสร้างความตระหนักรู้และเพิ่มพูนความเข้าใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) ให้มากยิ่งขึ้น โดยเจาะลึกเนื้อหาเกี่ยวกับประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล 5 ฉบับ ซึ่งบังคับใช้เพิ่มเติมในปี 2567 เพื่อให้องค์กรสามารถนำแนวปฏิบัติ มาตรฐานและวิธีการต่างๆ ไปประยุกต์ใช้กับแผนการดำเนินงานให้มีความสอดคล้องกับกฎหมาย PDPA ในปัจจุบันได้อย่างมีประสิทธิภาพ อีกทั้งเพื่อลดความเสี่ยงที่อาจนำไปสู่การรับผิดทางแพ่ง ทางอาญา หรือทางปกครองได้',
    courseTarget: [
      'เจ้าของธุรกิจหรือผู้บริหารองค์กร',
      'หัวหน้างาน',
      'นักทรัพยากรบุคคล (HR)',
      'นักกฎหมายหรือนักกลยุทธ์องค์กร',
      'พนักงานทั่วไป',
      'บุคคลทั่วไปที่สนใจกฎหมายคุ้มครอง ข้อมูลส่วนบุคคล',
    ],
    whatYouWillGet: [
      'ความรู้เกี่ยวกับประกาศย่อย 5 ฉบับที่จำเป็น ในปี 2567 เพื่อให้ผู้เรียนสามารถนำแนวปฏิบัติ มาตรฐานและวิธีการต่างๆ ไปประยุกต์ใช้กับแผนการดำเนินงานให้มีความสอดคล้องกับกฎหมาย PDPA ในปัจจุบันได้อย่างมีประสิทธิภาพ อีกทั้งเพื่อลดความเสี่ยงที่อาจนำไปสู่การรับผิดทางแพ่ง ทางอาญา หรือทางปกครองได้',
    ],
    sylabus: [
      'Timeline การบังคับใช้ PDPA และประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง มาตรการปกป้องที่เหมาะสมเพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล สำหรับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะ พ.ศ. 2566',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง หลักเกณฑ์การให้ความคุ้มครองข้อมูลส่วนบุคคลที่ส่งหรือโอนไปยังต่างประเทศตามมาตรา 28 แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 พ.ศ. 2566',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง หลักเกณฑ์การให้ความคุ้มครองข้อมูลส่วนบุคคลที่ส่งหรือโอนไปยังต่างประเทศตามมาตรา 29 แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562  พ.ศ. 2566',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง หลักเกณฑ์เกี่ยวกับมาตรการคุ้มครองสำหรับการเก็บรวบรวมข้อมูลส่วนบุคคลเกี่ยวกับประวัติอาชญากรรมที่มิได้กระทำภายใต้การควบคุมของหน่วยงานที่มีอำนาจหน้าที่ตามกฎหมาย พ.ศ. 2566',
      'ประกาศคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เรื่อง มาตรการที่เหมาะสมสำหรับการเก็บรวบรวมข้อมูลส่วนบุคคลเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการศึกษาวิจัยหรือสถิติตามมาตรา 24 (1) และการศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์ หรือสถิติ หรือประโยชน์สาธารณะอื่นตามมาตรา 26 (5) (ง) แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 พ.ศ. 2566',
    ],
    checklist: [
      'คอร์สความยาวประมาณ 1 ชั่วโมง',
      'เข้าเรียนได้ไม่จำกัดครั้งภายใน 1 ปี',
      'มีแบบทดสอบหลังเรียน',
      'มี certificate',
      'ออกแบบเนื้อหาโดยทีมผู้เชี่ยวชาญด้านกฎหมาย',
      'สอนโดยคุณชยานันต์ ไชยคำภา วิทยากรและที่ปรึกษาทางกฎหมาย ผู้เชี่ยวชาญด้านพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    ],
    teacherProfile: <PDPAAwarenessPart2Teacher />,
  },
  {
    title: 'HR PDPA Awareness Training',
    metaDescription:
      'หลักสูตรพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA) สำหรับงานบริหารทรัพยากรบุคคล',
    subtitle:
      'หลักสูตรนี้จัดทำขึ้นเพื่อสนับสนุนการบริหารงานทรัพยากรบุคคลและให้ความรู้ความเข้าใจเกี่ยวกับพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    listItemSubittle:
      'หลักสูตรพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA) สำหรับงานบริหารทรัพยากรบุคคล',
    slug: 'hr-pdpa-awareness-training',
    price: '1,199 ฿',
    coursePageLink:
      'https://study.learnpdpa.com/courses/hr-pdpa-awareness-training',
    takeCourseLink:
      'https://study.learnpdpa.com/courses/take/hr-pdpa-awareness-training/lessons/26373848-1',
    previewLink: 'https://study.learnpdpa.com/enroll/1494872?et=free_trial',
    image: '/images/Courses/Thumbnail-HRcourse.jpg',
    video: 'axjFIcIHYww',
    description:
      'หลักสูตรนี้จัดทำขึ้นเพื่อสนับสนุนการบริหารงานทรัพยากรบุคคลและให้ความรู้ความเข้าใจพนักงานเกี่ยวกับพ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) ที่จะมีผลบังคับใช้อย่างเต็มรูปแบบในปีพ.ศ. 2565 หลักสูตรนี้ไม่เพียงแต่จะช่วยให้ HR สามารถวางแผนและจัดการข้อมูลส่วนบุคคลของพนักงานเท่านั้น แต่ยังรวมถึงการบริหารจัดการข้อมูลส่วนบุคคลของผู้สมัครงานในกระบวนการจัดหางานอีกด้วย โดยเนื้อหาจะเน้นไปถึงการจัดการข้อมูลส่วนบุคคลอ่อนไหวของพนักงาน รวมถึงรายการเอกสารต่าง ๆ ที่จะช่วยให้ HR รักษามาตรการรักษาความปลอดภัยภายในบริษัทอีกด้วย',
    courseTarget: [
      'ทีมบริหารทรัพยากรบุคคล',
      'เจ้าของธุรกิจหรือผู้บริหารองค์กร',
      'ผู้ให้คำปรึกษาทางด้านกฎหมาย',
      'บุคคลทั่วไปที่สนใจ',
    ],
    whatYouWillGet: [
      'ความเข้าใจเกี่ยวกับ PDPA ในงาน HR',
      'สามารถวางแผนการบริหารงาน HR ให้สอดคล้องกับ PDPA',
      'เข้าใจวิธีการจัดการข้อมูลส่วนบุคคลของผู้สมัครงาน',
      'เพิ่มความตระหนักเกี่ยวกับผลกระทบของ PDPA',
      'รับทราบถึงเอกสารที่จำเป็นสำหรับการจัดมาตรการรักษาความปลอดภัยของข้อมูลส่วนบุคคล',
    ],
    sylabus: [
      'เนื้อหาบทเรียน',
      'ดาวน์โหลดสไลด์ประกอบการสอน',
      'ความรู้เพิ่มเติมเกี่ยวกับ PDPA สำหรับ HR',
      'กิจกรรมต่าง ๆ ของฝ่ายทรัพยากรบุคคล',
      'ความยินยอม vs. สัญญา',
      'คดีที่น่าสนใจของต่างประเทศ',
      'ผลกระทบของฝ่ายทรัพยากรบุคคลจากกฎหมายคุ้มครองข้อมูลส่วนบุคคล',
      'มาตรการที่ควรปฏิบัติเบื้องต้น',
      'Key to Success!',
      'คำถามที่พบบ่อยเกี่ยวกับกระบวนการสรรหาบุคลากรและการจ้างงาน',
      'คำถามที่พบบ่อยเกี่ยวกับมาตรการและกระบวนการดำเนินงานของฝ่าย HR',
      'คำถามที่พบบ่อยเกี่ยวกับความยินยอม',
    ],
    checklist: [
      'คอร์สความยาวประมาณ 1 ชั่วโมง',
      'เข้าเรียนได้ไม่จำกัดครั้งภายใน 1 ปี',
      'มีแบบทดสอบหลังเรียน',
      'มี certificate',
      'ออกแบบเนื้อหาโดยทีมผู้เชี่ยวชาญด้านกฎหมาย',
      'สอนโดยคุณกรกนก พรายแก้ว วิทยากรและที่ปรึกษาทางกฎหมาย ผู้เชี่ยวชาญด้านกฎหมายการค้าและพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    ],
    teacherProfile: <KornkanokP />,
  },
  {
    title: 'PDPA for DPO',
    metaDescription:
      'หลักสูตรกฎหมายคุ้มครองข้อมูลส่วนบุคคล สำหรับเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล',
    subtitle:
      'หลักสูตรนี้จัดทำขึ้นมาเพื่อให้ความรู้ ความเข้าใจ และแนวปฏิบัติแก่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (DPO)',
    listItemSubittle:
      'หลักสูตรกฎหมายคุ้มครองข้อมูลส่วนบุคคล สำหรับเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล',
    slug: 'pdpa-for-dpo',
    price: '1,999 ฿',
    coursePageLink: 'https://study.learnpdpa.com/courses/pdpa-for-dpo',
    takeCourseLink:
      'https://study.learnpdpa.com/courses/take/pdpa-for-dpo/lessons/51724199-pdpa-for-dpo',
    previewLink: 'https://study.learnpdpa.com/enroll/2820386?et=free_trial',
    image: '/images/Courses/Thumbnail-PDPAForDPOcourse.jpg',
    video: 'QbbrXsT4g5U',
    description:
      'หลักสูตรนี้จัดทำขึ้นมาเพื่อให้ความรู้ ความเข้าใจเกี่ยวกับ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) สำหรับเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (DPO) เพื่อสร้างความตระหนักรู้ในผลกระทบของการบังคับใช้กฎหมาย สร้างความรู้ความเข้าใจเกี่ยวกับทักษะ บทบาทหน้าที่ ข้อพึงระวังและมาตรฐานทางจริยธรรมของ DPO เพื่อให้สามารถปฏิบัติหน้าที่และให้คำแนะนำแก่องค์กรตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลได้อย่างถูกต้อง เพื่อลดความเสี่ยงที่จะนำไปสู่การรับผิดทางแพ่งและอาญา',
    courseTarget: [
      'ผู้ที่ได้รับมอบหมายให้ทำหน้าที่เป็นเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (DPO) ของบริษัท',
      'ผู้ที่ได้รับมอบหมายให้ดำเนินการด้านกฎหมายคุ้มครองข้อมูลส่วนบุคคล (PDPA) ของบริษัท',
      'หัวหน้างาน',
      'เจ้าของธุรกิจหรือผู้บริหาร',
      'บุคคลทั่วไปที่สนใจเกี่ยวกับกฎหมายคุ้มครองข้อมูลส่วนบุคคล PDPA',
    ],
    whatYouWillGet: [
      'ความรู้ทั่วไปเกี่ยวกับ PDPA และแนวปฏิบัติ',
      'ความรู้ความเข้าใจเกี่ยวกับบทบาทหน้าที่และทักษะที่ต้องใช้ของ DPO และแนวปฏิบัติ',
    ],
    sylabus: [
      'แนะนำคอร์สเรียน',
      'ดาวน์โหลดสไลด์ประกอบการสอน',
      '7 หลักการสำคัญของกฎหมาย PDPA',
      'ข้อมูลส่วนบุคคล และการประมวลผลข้อมูลส่วนบุคคลคืออะไร และบุคคลที่ตกอยู่ภายใต้บังคับของ PDPA',
      'ฐานทางกฎหมายในการประมวลผลข้อมูล สิทธิของเจ้าของข้อมูล',
      'หน้าที่ของผู้ควบคุมข้อมูลส่วนบุคคล',
      'หน้าที่ของผู้ประมวลผลข้อมูลส่วนบุคคล',
      'แนวทางการปฏิบัติงานที่ DPO ต้องทราบ',
      'ทักษะและคุณสมบัติของ DPO',
      'ข้อพึงระวังในการปฏิบัติงานของ DPO',
      'บทบาทหน้าที่และความรับผิดชอบของ DPO',
      'มาตรฐานทางจริยธรรมของ DPO',
      'บันทึกกิจกรรมการประมวลผลข้อมูลส่วนบุคคล (ROPA)',
      'หลักเกณฑ์และวิธีการในการแจ้งเหตุละเมิดข้อมูลส่วนบุคคล',
      'โทษของการฝ่าฝืน PDPA',
      'PDPA Compliance Checklist และการดำเนินการตามกฎหมาย PDPA',
      'แบบทดสอบหลังเรียน',
    ],
    checklist: [
      'คอร์สความยาวประมาณ 1 ชั่วโมง',
      'เข้าเรียนได้ไม่จำกัดครั้งภายใน 1 ปี',
      'มีแบบทดสอบหลังเรียน',
      'มี certificate',
      'ออกแบบเนื้อหาโดยทีมผู้เชี่ยวชาญด้านกฎหมาย',
      'สอนโดยคุณชยานันต์ ไชยคำภา และคุณสิปปกร บุญรักษา วิทยากรและที่ปรึกษาทางกฎหมาย ผู้เชี่ยวชาญด้านพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
    ],
    teacherProfile: <PDPAForDPOTeacher />,
  },
]
