export const titleStyle = {
  fontSize: { base: '2xl', sm: '2xl' },
}

export const containerStyle = { alignItems: 'stretch', color: 'primary.800' }

export const paragraphStyle = {
  color: 'gray.850',
  fontWeight: 'light',
  fontSize: 'lg',
}

export const listItemStyle = {
  ...paragraphStyle,
  listStylePosition: 'inside',
}
