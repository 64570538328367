import { Collapse, Flex, CSSObject, useTheme } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'

interface Props {
  id?: string
  sx?: CSSObject
  isOpen?: boolean
  children?: React.ReactNode
}

function Banner({ id, children, sx, isOpen }: Props): React.ReactElement {
  const theme = useTheme()

  return (
    <Collapse id={id} in={isOpen}>
      <Flex
        sx={{
          bg: transparentize(theme.colors.tertiary[600], 0.8),
          color: 'white',
          w: '100%',
          justifyContent: 'center',
          minH: 10,
          py: 2,
          px: 4,
          textAlign: 'center',
          ...sx,
        }}
      >
        {children}
      </Flex>
    </Collapse>
  )
}

Banner.propTypes = {}

Banner.defaultProps = {
  isOpen: false,
  className: '',
}

export default Banner
