export const homeRoute: Link = {
  pathname: '/',
}

export const courseRoute: Link = {
  pathname: '/courses/[slug]',
}

export const privacyPolicyRoute: Link = {
  pathname: '/privacy-policy',
}

export const termsOfUseRoute: Link = {
  pathname: '/terms-of-use',
}
