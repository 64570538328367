import { Icon, CSSObject } from '@chakra-ui/react'
import { FaPhoneAlt } from 'react-icons/fa'

import { FACEBOOK, TEL } from '@/routes/external'
import { courseRoute } from '@/routes'

import COLORS from '@/styles/styles/colors'
import { COURSES } from '@/features/Courses/constants'

import { ReactComponent as Facebook } from '@/svg/logo-facebook.svg'

type NavLink = {
  label: React.ReactChild
  link: Link
  sx?: CSSObject
  onClick?: () => void
  color?: string
  backgroundColor?: string
  divided?: boolean
  subMenus?: NavLink[]
}

const menuStyle = {
  minW: '180px',
}

const subMenusStyle = {
  ...menuStyle,
  h: { base: 10, md: '48px' },
  fontSize: 'sm',
}

export const LINKS: NavLink[] = [
  {
    label: 'ทำไมต้องเรา',
    link: {
      pathname: '/',
      hash: 'why-us',
    },
    sx: menuStyle,
    backgroundColor: '#ffffff',
  },
  {
    label: 'คอร์สเรียนทั้งหมด',
    link: {
      pathname: 'currentPath',
      hash: 'our-courses',
    },
    sx: menuStyle,
    backgroundColor: '#ffffff',
    subMenus: COURSES.map((course) => {
      return {
        label: course.title,
        link: {
          ...courseRoute,
          query: {
            slug: course.slug,
          },
        },
        sx: subMenusStyle,
        backgroundColor: '#ffffff',
      }
    }),
  },
  {
    label: 'คำถามที่พบบ่อย',
    link: {
      pathname: '/',
      hash: 'faq',
    },
    sx: menuStyle,
    backgroundColor: '#ffffff',
  },
  {
    label: 'Blog',
    link: {
      pathname: '/',
      hash: 'blogs',
    },
    sx: menuStyle,
    backgroundColor: '#ffffff',
  },
  {
    label: 'เข้าสู่ระบบ',
    link: {
      pathname: 'https://study.learnpdpa.com/users/sign_in',
      isExternal: true,
    },
    sx: {
      minW: '160px',
    },
    backgroundColor: COLORS.primary[500],
    color: '#ffffff',
  },
  {
    label: <Icon as={Facebook} boxSize="6" />,
    link: FACEBOOK,
    backgroundColor: '#3B5999',
    color: '#ffffff',
  },
  {
    label: <Icon as={FaPhoneAlt} boxSize="6" />,
    link: TEL,
    backgroundColor: '#ffffff',
  },
]

export const NAVIGATION_BAR_HEIGHT = {
  base: '60px',
  md: '72px',
}

export const PROMOTION_TEXT = ''
