import { useRef, useCallback, useEffect } from 'react'
import { CSSObject, Flex, LinkBox, LinkOverlay, Box } from '@chakra-ui/react'
import { darken } from '@chakra-ui/theme-tools'
import { useSpring } from 'react-spring'

import ScrollLink from '@/lib/scroll'

import {
  AnimatedAspectRatio,
  DEFAULT_SPRING_CONFIG,
} from '@/lib/springComponent'

interface Props {
  label: React.ReactChild
  link: Link
  onClick?: () => void
  color?: string
  backgroundColor?: string
  sx?: CSSObject
  id?: string
}

function RippleButton({
  color = 'gray.800',
  backgroundColor = '#FFFFFF',
  onClick,
  label,
  link,
  sx,
  id,
}: Props): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null)

  const [spring, springApi] = useSpring(() => {
    return {
      translate3d: 'calc(-50% + 0px), calc(-50% + 0px), 0',
      scale: 1,
      opacity: 0,
      config: DEFAULT_SPRING_CONFIG,
    }
  })

  useEffect(() => {
    const handleMouseUp = () => {
      springApi.start({
        opacity: 0,
      })
    }
    document.addEventListener('mouseup', handleMouseUp)

    return () => document.removeEventListener('mouseup', handleMouseUp)
  }, [])

  // ripple
  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (!containerRef.current) return

      const { top, left, bottom, right, width } =
        containerRef.current.getBoundingClientRect()

      const offsets: [number, number][] = []

      offsets.push([event.clientX - left, event.clientY - top])
      offsets.push([event.clientX - left, event.clientY - bottom])
      offsets.push([event.clientX - right, event.clientY - bottom])
      offsets.push([event.clientX - right, event.clientY - top])

      springApi.set({
        scale: 0,
        translate3d: `calc(-50% + ${offsets[0][0]}px), calc(-50% + ${offsets[0][1]}px), 0`,
        opacity: 0,
      })

      const distants: number[] = offsets.map(([x, y]) =>
        Math.sqrt(x * x + y * y),
      )

      const scale = (2 * Math.max(...distants)) / width

      springApi.start({
        scale: scale,
        opacity: 1,
      })
    },
    [containerRef.current],
  )

  return (
    <LinkBox
      id={id}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      sx={{
        h: 'full',
        minW: {
          base: 15,
          md: 18,
        },
        ...sx,
        position: 'relative',
        overflow: 'hidden',
        bg: backgroundColor,
        transition: 'background-color 250ms',
        userSelect: 'none',
        cursor: 'pointer',
        '&:hover': {
          bg: darken(backgroundColor, 5),
        },
      }}
    >
      <AnimatedAspectRatio
        ratio={1}
        style={spring}
        sx={{
          w: 'full',
          borderRadius: 'full',
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Box
          sx={{
            w: 'full',
            h: 'full',
            bg: darken(backgroundColor, 10),
          }}
        />
      </AnimatedAspectRatio>
      <Flex
        sx={{
          w: 'full',
          h: 'full',
          px: 6,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          fontWeight: 'medium',
          color: color,
        }}
      >
        {link.isExternal ? (
          <LinkOverlay
            isExternal={link.isExternal}
            href={link.pathname}
            onClick={onClick}
          >
            {label}
          </LinkOverlay>
        ) : (
          <LinkOverlay
            as={ScrollLink}
            url={link}
            onClick={onClick}
            sx={{
              color: 'inherit !important',
            }}
          >
            {label}
          </LinkOverlay>
        )}
      </Flex>
    </LinkBox>
  )
}

export default RippleButton
