import {
  Box,
  Flex,
  Stack,
  VStack,
  HStack,
  AspectRatio,
  Button,
  Grid,
  Input,
  Text,
} from '@chakra-ui/react'
import { animated } from 'react-spring'

export const AnimatedBox = animated(Box)
export const AnimatedFlex = animated(Flex)
export const AnimatedStack = animated(Stack)
export const AnimatedVStack = animated(VStack)
export const AnimatedHStack = animated(HStack)
export const AnimatedAspectRatio = animated(AspectRatio)
export const AnimatedButton = animated(Button)
export const AnimatedGrid = animated(Grid)
export const AnimatedInput = animated(Input)
export const AnimatedText = animated(Text)

export const DEFAULT_SPRING_CONFIG = {
  mass: 1,
  tension: 500,
  friction: 50,
}
