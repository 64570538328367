import { useEffect, useRef } from 'react'
import { CSSObject } from '@chakra-ui/react'
import { useSpring, animated } from 'react-spring'

import {
  AnimatedAspectRatio,
  DEFAULT_SPRING_CONFIG,
} from '@/lib/springComponent'

interface Props {
  onClick: () => void
  sx?: CSSObject
  isOpen?: boolean
  id?: string
}

function Hamburger({
  isOpen = false,
  onClick,
  id,
  sx,
}: Props): React.ReactElement {
  const hoverRef = useRef(false)

  const [
    { transformTop, transformMiddle, transformBottom, opacity },
    springsApi,
  ] = useSpring(() => {
    return {
      transformTop: 'rotate(0deg) translate3d(0px, 0px, 0)',
      transformMiddle: 'rotate(0deg) translate3d(0px, 0px, 0)',
      transformBottom: 'rotate(0deg) translate3d(0px, 0px, 0)',
      opacity: 1,
      config: DEFAULT_SPRING_CONFIG,
    }
  })

  useEffect(() => {
    if (hoverRef.current) {
      if (!isOpen) {
        springsApi.start({
          transformTop: 'rotate(0deg) translate3d(0px, 8px, 0)',
          transformBottom: 'rotate(0deg) translate3d(0px, -8px, 0)',
          opacity: 1,
        })
      } else {
        springsApi.start({
          transformTop: 'rotate(45deg) translate3d(0px, 8px, 0)',
          transformBottom: 'rotate(135deg) translate3d(0px, -8px, 0)',
          opacity: 0,
        })
      }
    } else if (!isOpen) {
      hoverRef.current = false

      springsApi.start({
        transformTop: 'rotate(0deg) translate3d(0px, 0px, 0)',
        transformMiddle: 'rotate(0deg) translate3d(0px, 0px, 0)',
        transformBottom: 'rotate(0deg) translate3d(0px, 0px, 0)',
        opacity: 1,
      })
    }
  }, [isOpen])

  return (
    <AnimatedAspectRatio
      id={id}
      ratio={1}
      layerStyle="actionable"
      onMouseEnter={() => {
        hoverRef.current = true
        if (!isOpen) {
          springsApi.start({
            transformTop: 'rotate(0deg) translate3d(0px, 8px, 0)',
            transformMiddle: 'rotate(0deg) translate3d(-4px, 0px, 0)',
            transformBottom: 'rotate(0deg) translate3d(0px, -8px, 0)',
            opacity: 0,
          })
        }
      }}
      onMouseLeave={() => {
        hoverRef.current = false
        if (!isOpen) {
          springsApi.start({
            transformTop: 'rotate(0deg) translate3d(0px, 0px, 0)',
            transformMiddle: 'rotate(0deg) translate3d(0px, 0px, 0)',
            transformBottom: 'rotate(0deg) translate3d(0px, 0px, 0)',
            opacity: 1,
          })
        }
      }}
      onClick={onClick}
      sx={{
        h: 'full',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
          p: 4,
        },
        '& rect': {
          transformOrigin: '12px 9px',
        },
        ...sx,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="-3 -3 30 24"
      >
        <g fill="none">
          <g fill="currentColor">
            <g>
              <animated.rect
                style={{
                  transform: transformTop,
                }}
                width="24"
                height="2"
                x="0"
                y="0"
                rx="1"
              />
              <animated.rect
                style={{
                  transform: transformMiddle,
                  opacity,
                }}
                width="16"
                height="2"
                x="8"
                y="8"
                rx="1"
              />
              <animated.rect
                style={{
                  transform: transformBottom,
                }}
                width="24"
                height="2"
                y="16"
                rx="1"
              />
            </g>
          </g>
        </g>
      </svg>
    </AnimatedAspectRatio>
  )
}

export default Hamburger
