import { Fragment } from 'react'
import { Box, Divider, Flex, Collapse } from '@chakra-ui/react'

import RippleButton from './RippleButton'

import { LINKS, NAVIGATION_BAR_HEIGHT } from './constants'

interface Props {
  isOpen?: boolean
  onClose?: () => void
}

function MenuMobile({ isOpen = false, onClose }: Props): React.ReactElement {
  return (
    <Collapse in={isOpen}>
      <Flex
        sx={{
          w: 'full',
          gridTemplateRows: `repeat(${LINKS.length}, ${NAVIGATION_BAR_HEIGHT.base})`,
          h: 'fit-content',
          flexFlow: 'column nowrap',
        }}
      >
        {LINKS.map(({ divided, ...link }, index) => {
          return (
            <Fragment key={`${index}`}>
              <Box
                sx={{
                  flex: `1 0 ${NAVIGATION_BAR_HEIGHT.base}`,
                  h: `${NAVIGATION_BAR_HEIGHT.base}`,
                }}
              >
                <RippleButton
                  {...link}
                  onClick={() => {
                    if (onClose) onClose()
                    if (link.onClick) link.onClick()
                  }}
                />
              </Box>
              {divided && (
                <Divider sx={{ opacity: 1, borderColor: 'gray.200' }} />
              )}
              {link.subMenus &&
                link.subMenus.map(({ divided, ...submenu }, index) => (
                  <Fragment key={`${index}`}>
                    <Box
                      sx={{
                        h: 'fit-content',
                      }}
                    >
                      <RippleButton
                        {...submenu}
                        onClick={() => {
                          if (onClose) onClose()
                          if (link.onClick) link.onClick()
                        }}
                      />
                    </Box>
                    {divided && (
                      <Divider sx={{ opacity: 1, borderColor: 'gray.200' }} />
                    )}
                  </Fragment>
                ))}
            </Fragment>
          )
        })}
      </Flex>
    </Collapse>
  )
}

export default MenuMobile
