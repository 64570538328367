export const FACEBOOK: Link = {
  pathname: 'https://www.facebook.com/pdpacore/',
  isExternal: true,
}

export const TEL: Link = {
  pathname: 'tel:020245560',
  isExternal: true,
}

export const LINKED_IN: Link = {
  pathname: 'https://www.linkedin.com/company/datawowio/',
  isExternal: true,
}

export const MEDIUM: Link = {
  pathname: 'https://datawow.io/blogs/',
  isExternal: true,
}

export const BLOGS: Link = {
  pathname: 'https://pdpacore.com/blogs/',
  isExternal: true,
}

export const PDPACORE: Link = {
  pathname: 'https://pdpacore.com/',
  isExternal: true,
}

export const PDPACORETRAINING: Link = {
  pathname: 'https://pdpacore.com/services/training/',
  isExternal: true,
}
