import { VStack, Text, UnorderedList, ListItem } from '@chakra-ui/react'

import { listItemStyle } from '../components/CourseNavigation/styles'

export function KornkanokP(): React.ReactElement {
  return (
    <VStack sx={{ alignItems: 'flex-start' }} spacing="5">
      <Text sx={{ fontWeight: 'medium' }}>
        คุณกรกนก พรายแก้ว - Senior legal consultant
      </Text>
      <VStack sx={{ alignItems: 'flex-start' }}>
        <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
          ประสบการณ์
        </Text>
        <UnorderedList>
          <ListItem sx={listItemStyle}>
            ให้คำปรึกษาเกี่ยวกับกฎหมายการค้าและแนวปฏิบัติตาม พ.ร.บ.
            คุ้มครองข้อมูลส่วนบุคคล (PDPA) กับองค์กรขนาดใหญ่ ทั้งในและต่างประเทศ
          </ListItem>
          <ListItem sx={listItemStyle}>
            วิทยากรในการอบรม PDPA ในหลากหลายอุตสาหกรรม ทั้งอุตสาหกรรมยานยนต์
            อสังหาริมทรัพย์ และโซเชียลมีเดีย
          </ListItem>
        </UnorderedList>
      </VStack>
      <VStack sx={{ alignItems: 'flex-start' }}>
        <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
          การศึกษา
        </Text>
        <UnorderedList>
          <ListItem sx={listItemStyle}>
            นิติศาสตร์บัณฑิต มหาวิทยาลัยธรรมศาสตร์
          </ListItem>
          <ListItem sx={listItemStyle}>
            นิติศาสตร์มหาบัณฑิต สาขากฎหมายธุรกิจระหว่างประเทศ Queen Mary
            University of London สหราชอาณาจักร
          </ListItem>
          <ListItem sx={listItemStyle}>
            ได้รับใบอนุญาตทนายความจากสภาทนายความ ประกาศนียบัตร
            ได้รับใบรับรองการดำเนินการตาม PDPA
          </ListItem>
        </UnorderedList>
      </VStack>
      <VStack sx={{ alignItems: 'flex-start' }}>
        <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
          ประกาศนียบัตร
        </Text>
        <UnorderedList>
          <ListItem sx={listItemStyle}>
            ได้รับใบรับรองการดำเนินการตาม PDPA
          </ListItem>
          <ListItem sx={listItemStyle}>
            นิติศาสตร์มหาบัณฑิต สาขากฎหมายธุรกิจระหว่างประเทศ Queen Mary
            University of London สหราชอาณาจักร
          </ListItem>
          <ListItem sx={listItemStyle}>
            ได้รับใบรับรองการผ่านหลักสูตรกฎหมายคุ้มครองข้อมูลส่วนบุคคลสำหรับเจ้าหน้าที่ผู้ปฏิบัติงาน
            ในหน่วยงานและองค์กรธุรกิจ
          </ListItem>
          <ListItem sx={listItemStyle}>
            ได้รับการรับรองเป็นเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลตาม GDPR
            จากสถาบัน Bureau Veritas CEPAS : Data Protection Officer (DPO)
          </ListItem>
          <ListItem sx={listItemStyle}>
            ได้รับใบรับรองการผ่านหลักสูตร ISO/IEC 27001: Information Security
            Management Systems, BSI
          </ListItem>
          <ListItem sx={listItemStyle}>
            ได้รับใบรับรองการผ่านหลักสูตร ISO/IEC 27701: Privacy Information
            Management Systems, BSI
          </ListItem>
        </UnorderedList>
      </VStack>
    </VStack>
  )
}

export function PDPAForDPOTeacher(): React.ReactElement {
  return (
    <VStack spacing={8}>
      <VStack sx={{ alignItems: 'flex-start' }} spacing="5">
        <Text sx={{ fontWeight: 'medium' }}>
          คุณชยานันต์ ไชยคำภา - Legal Counsel Manager
        </Text>
        <VStack sx={{ alignItems: 'flex-start' }}>
          <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
            ประสบการณ์
          </Text>
          <UnorderedList>
            <ListItem sx={listItemStyle}>
              ให้คำปรึกษาด้านกฎหมายในเรื่องธุรกิจและการปฏิบัติตามกฎหมาย รวมถึง
              พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล
              แก่บริษัทและลูกค้าในหลากหลายอุตสาหกรรม รวมถึงร่างเอกสารทางกฎหมาย
              ในบริษัท e-commerce ชั้นนำ
            </ListItem>
            <ListItem sx={listItemStyle}>
              ตรวจสอบความเสี่ยงในการปฏิบัติตามกฎหมายเกี่ยวกับกฎระเบียบ
              พัฒนานโยบาย และจัดการฝึกอบรม
            </ListItem>
            <ListItem sx={listItemStyle}>
              มีส่วนร่วมในการคุ้มครองข้อมูลในฐานะส่วนหนึ่งของคณะกรรมการความปลอดภัยข้อมูลบริษัท
            </ListItem>
          </UnorderedList>
        </VStack>
        <VStack sx={{ alignItems: 'flex-start' }}>
          <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
            การศึกษา
          </Text>
          <Text>นิติศาสตรบัณฑิต จุฬาลงกรณ์มหาวิทยาลัย</Text>
        </VStack>
      </VStack>
      <VStack sx={{ alignItems: 'flex-start' }} spacing="5">
        <Text sx={{ fontWeight: 'medium' }}>
          คุณสิปปกร บุญรักษา - Legal Counsel
        </Text>
        <VStack sx={{ alignItems: 'flex-start' }}>
          <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
            ประสบการณ์
          </Text>
          <UnorderedList>
            <ListItem sx={listItemStyle}>
              ให้คำปรึกษาด้านกฎหมายในเรื่องธุรกิจและการปฏิบัติตามกฎหมาย
              รวมถึงพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล
            </ListItem>
          </UnorderedList>
        </VStack>
        <VStack sx={{ alignItems: 'flex-start' }}>
          <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
            การศึกษา
          </Text>
          <Text>นิติศาสตรบัณฑิต มหาวิทยาลัยธรรมศาสตร์</Text>
        </VStack>
      </VStack>
    </VStack>
  )
}

export function PDPAAwarenessPart2Teacher(): React.ReactElement {
  return (
    <VStack spacing={8}>
      <VStack sx={{ alignItems: 'flex-start' }} spacing="5">
        <Text sx={{ fontWeight: 'medium' }}>
          คุณชยานันต์ ไชยคำภา - Legal Counsel Manager
        </Text>
        <VStack sx={{ alignItems: 'flex-start' }}>
          <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
            ประสบการณ์
          </Text>
          <UnorderedList>
            <ListItem sx={listItemStyle}>
              ให้คำปรึกษาด้านกฎหมายที่เกี่ยวข้องกับการดำเนินธุรกิจในประเทศไทย
              โดยรวมถึงกฎหมายคุ้มครองข้อมูลส่วนบุคคล
              ให้แก่บริษัทและลูกค้าชั้นนำในหลากหลายอุตสาหกรรม ไม่ว่าจะเป็นบริษัท
              e-commerce บริษัทค้าปลีก
              และบริษัทผู้พัฒนาระบบคอมพิวเตอร์และซอฟต์แวร์
            </ListItem>
            <ListItem sx={listItemStyle}>
              ช่วยองค์กรตรวจสอบและประเมินความเสี่ยงด้านกฎหมาย
              โดยการชี้แนะแนวทางการปฏิบัติตามกฎหมายให้กับองค์กร
              เพื่อการพัฒนากฎระเบียบ นโยบาย
              และแผนการดำเนินงานให้สอดคล้องกับกฎหมายอย่างมีประสิทธิภาพ
            </ListItem>
            <ListItem sx={listItemStyle}>
              เป็นส่วนหนึ่งของคณะทำงานด้านการคุ้มครองข้อมูลส่วนบุคคลขององค์กร
            </ListItem>
          </UnorderedList>
        </VStack>
        <VStack sx={{ alignItems: 'flex-start' }}>
          <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
            การศึกษา
          </Text>
          <Text>นิติศาสตรบัณฑิต จุฬาลงกรณ์มหาวิทยาลัย</Text>
        </VStack>
      </VStack>
      <VStack sx={{ alignItems: 'flex-start' }} spacing="5">
        <VStack sx={{ alignItems: 'flex-start' }}>
          <Text sx={{ fontWeight: 'medium', textDecoration: 'underline' }}>
            ใบรับรอง
          </Text>
          <UnorderedList>
            <ListItem sx={listItemStyle}>
              ใบรับรองการผ่านหลักสูตรเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
              รับรองโดยสภาดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งประเทศไทยและ ACIS
              Professional Center
            </ListItem>
            <ListItem sx={listItemStyle}>
              ใบรับรองการผ่านหลักสูตร ISO/IEC 27001:2022 Implementation of
              Information Security Management Systems, BSI
            </ListItem>
            <ListItem sx={listItemStyle}>
              ได้รับใบรับรองการผ่านหลักสูตร ISO/IEC 27701:2019 Implementation of
              Privacy Information Management Systems, BSI
            </ListItem>
          </UnorderedList>
        </VStack>
      </VStack>
    </VStack>
  )
}
