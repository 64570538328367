import { Fragment } from 'react'
import { Flex, Divider } from '@chakra-ui/react'

import FlipButton from './FlipButton'
import RippleButton from './RippleButton'

import { LINKS } from './constants'

export const NAVIGATION_BAR_HEIGHT = {
  base: '60px',
  md: '72px',
}

function MenuDesktop(): React.ReactElement {
  return (
    <Flex
      sx={{
        display: {
          base: 'none',
          md: 'flex',
        },
        h: 'full',
        flexFlow: 'row nowrap',
      }}
    >
      {LINKS.map(({ divided, ...link }, index) => {
        return (
          <Fragment key={`${index}`}>
            {link.link.isExternal ? (
              <RippleButton
                {...link}
                onClick={() => {
                  if (link.onClick) link.onClick()
                }}
              />
            ) : (
              <FlipButton
                {...link}
                onClick={() => {
                  if (link.onClick) link.onClick()
                }}
              />
            )}
            {index < LINKS.length - 1 && divided && (
              <Divider orientation="vertical" />
            )}
          </Fragment>
        )
      })}
    </Flex>
  )
}

export default MenuDesktop
