import { useRef } from 'react'
import {
  CSSObject,
  LinkBox,
  LinkOverlay,
  Box,
  useTheme,
  useDisclosure,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
} from '@chakra-ui/react'
import { useSpring } from 'react-spring'

import ScrollLink from '@/lib/scroll'

import {
  AnimatedBox,
  AnimatedFlex,
  DEFAULT_SPRING_CONFIG,
} from '@/lib/springComponent'

import RippleButton from './RippleButton'

interface Props {
  label: React.ReactChild
  link: Link
  onClick?: () => void
  color?: string
  backgroundColor?: string
  sx?: CSSObject
  id?: string
  subMenus?: Props[]
}

function FlipButton({
  color = 'gray.800',
  onClick,
  label,
  link,
  sx,
  id,
  subMenus,
}: Props): React.ReactElement {
  const theme = useTheme()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const containerRef = useRef<HTMLDivElement>(null)

  const [containerSpring, containerSpringApi] = useSpring(() => {
    return {
      color: theme.colors.primary[800],
      transform: 'perspective(1000px) rotateX(90deg)',
      opacity: 0,
      config: DEFAULT_SPRING_CONFIG,
    }
  })

  return (
    <Box
      sx={{ pos: 'relative', h: 'full' }}
      onMouseLeave={() => {
        containerSpringApi.start({
          color: theme.colors.primary[800],
          transform: 'perspective(1000px) rotateX(90deg)',
          opacity: 0,
        })
      }}
    >
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-start"
        trigger="hover"
        isLazy
        lazyBehavior="keepMounted"
        matchWidth
      >
        <PopoverTrigger>
          <LinkBox
            id={id}
            ref={containerRef}
            onMouseEnter={() => {
              containerSpringApi.start({
                color: '#FFFFFF',
                transform: 'perspective(1000px) rotateX(0deg)',
                opacity: 1,
              })
            }}
            sx={{
              h: 'full',
              minW: {
                base: 15,
                md: 18,
              },
              ...sx,
              position: 'relative',
              userSelect: 'none',
              cursor: 'pointer',
            }}
          >
            <AnimatedBox
              style={{
                transform: containerSpring.transform,
                opacity: containerSpring.opacity,
              }}
              sx={{
                w: 'full',
                h: '95%',
                borderRadius: '8px 8px 0 0',
                position: 'absolute',
                bottom: 0,
                left: 0,
                transformOrigin: 'center bottom',
              }}
            >
              <Box
                sx={{
                  borderRadius: '8px 8px 0 0',
                  w: 'full',
                  h: 'full',
                  bg: 'primary.800',
                }}
              />
            </AnimatedBox>
            <AnimatedFlex
              style={{
                color: containerSpring.color,
              }}
              sx={{
                w: 'full',
                h: 'full',
                px: 6,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                fontWeight: 'medium',
                color: color,
              }}
            >
              {link.isExternal ? (
                <LinkOverlay
                  isExternal={link.isExternal}
                  href={link.pathname}
                  onClick={onClick}
                >
                  {label}
                </LinkOverlay>
              ) : (
                <LinkOverlay
                  as={ScrollLink}
                  url={link}
                  onClick={onClick}
                  sx={{
                    color: 'inherit !important',
                  }}
                >
                  {label}
                </LinkOverlay>
              )}
            </AnimatedFlex>
          </LinkBox>
        </PopoverTrigger>
        {subMenus && (
          <PopoverContent
            sx={{
              w: 'fit-content',
              boxShadow: 'none !important',
              border: 'none',
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              transition: isOpen ? 'none' : 'visibility 0ms 150ms',
              visibility: isOpen ? 'visible' : 'hidden !important',
            }}
          >
            <PopoverBody
              sx={{
                m: 0,
                p: 0,
                borderRadius: 'md',
                borderColor: 'gray.300',
                backgroundColor: 'white',
                overflow: 'hidden',
                boxShadow: '0 2px 8px 1px #2f2f2f2f',
              }}
            >
              {subMenus.map(({ sx, ...menu }, index) => (
                <RippleButton
                  key={`${index}`}
                  {...menu}
                  sx={{
                    ...sx,
                    '& > *:last-of-type': {
                      justifyContent: 'flex-start',
                    },
                  }}
                />
              ))}
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  )
}

export default FlipButton
