import { Box, chakra, Container as CKContainer } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    w: '100%',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: 'font.600',
    fontSize: '14px',
    strong: {
      fontWeight: 700,
      color: 'font.600',
    },
  },
})

export const Container = chakra(CKContainer, {
  baseStyle: {
    maxW: ['80%', '100vw', '100vw', '1113px'],
    px: ['0', '32px', '32px', '0'],
  },
})
